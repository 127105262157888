
import {defineComponent, onMounted} from "vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import Overview from "@/view/pages/resources/documentation/base/carousel/Overview.vue";
import CustomExample from "@/view/pages/resources/documentation/base/carousel/CustomExample.vue";

export default defineComponent({
  name: "carousel",
  components: {
    Overview,
    CustomExample,
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Carousel");
    });
  },
});
